<template>
  <div id="Coordenador_Progresso_Turmas" class="pl-1" v-if="validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)">
    <component :is="componentEmQuestao" @mudaComponente="changeCP" />
  </div>
</template>

<script>
import theAdministradorAdministradores from "../components/administrador/theAdministradorAdministradores";
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      componentEmQuestao: theAdministradorAdministradores,
    };
  },
  components: {
    theAdministradorAdministradores,
  },
  mounted(){
  },
  computed:{
    ...mapGetters(['getUserDatas'])
  },
  methods: {
    changeCP(nomeDoComponente) {
      this.componentEmQuestao = nomeDoComponente;
    },    
  },
};
</script>

<style scoped></style>
